import React from 'react';
// import PropTypes from 'prop-types';
// import { graphql } from 'gatsby';
import Layout from 'components/layout';
import AboutContainer from 'components/aboutContainer';
import Head from 'components/head';
import PageContainer from 'components/pageContainer';
// import SecondaryHeader from 'components/secondaryHeader';
import ContactForm from '../components/contactForm/contactForm';
import ContactInfo from '../components/contactInfo/contactInfo';

const Contact = () => (
  <Layout>
    <Head pageTitle="Contact" />
    {/* <SecondaryHeader title="Contact" /> */}
    <PageContainer>
      <AboutContainer>
        <ContactInfo success={true} />
        <ContactForm />
      </AboutContainer>
    </PageContainer>
  </Layout>
);

// Contact.propTypes = {
//   data: PropTypes.object.isRequired,
// };

export default Contact;

// export const query = graphql`
//   query AboutQuery {
//     aboutJson {
//       title
//       content {
//         childMarkdownRemark {
//           html
//         }
//       }
//     }
//   }
// `;
